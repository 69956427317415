import React, { useState } from 'react';
import GroupService from '../services/GroupService';
import { connect } from 'react-redux';
import { addError } from '../redux/actions/request';
import UserContext from './UserContext';

const InviteCodeContext = React.createContext();
const { Provider, Consumer } = InviteCodeContext;

const InviteCodeProvider = (props) => {
  const { user } = React.useContext(UserContext);
  const [inviteCodes, setInviteCodes] = useState([]);
  const [userGroupData, setUserGroupData] = useState({});
  const [userGroups, setUserGroups] = useState([]);
  const [userGroupsCount, setUserGroupsCount] = useState(0);


  const handleFetchInviteCodes = async () => {
    try {
      setInviteCodes([]);
      const params = {};
      const data = await GroupService.getGroups(params);
      setInviteCodes(data.data);
    } catch (error) {
      console.log('Error fetch groups:', error.message);
      props.addError(error);
    }
  };

  const handleFetchInviteCodesForPanel = async () => {
    try {
      setInviteCodes([]);
      const params = {};
      const data = await GroupService.getGroupsForPanel(params);
      setInviteCodes(data.data);
    } catch (error) {
      console.log('Error fetch groups:', error.message);
      props.addError(error);
    }
  };

  const handleFetchGroupsByOwner = async (owner) => {
    try {
      if (!owner) owner = user.email;
      const data = await GroupService.getGroupsByUser({ email: owner });
      setInviteCodes(data.data);
    } catch (e) {
      console.error('Error fetch groups: ', e.message);
      props.addError(e);
    }
  };

  const handleFetchUserGroup = async (id) => {
    try {
      const data = await GroupService.getUserGroup(id);
      setUserGroupData(data);
    } catch (e) {
      console.error('Error fetching user group: ', e.message);
      props.addError(e);
    }
  };

  const handleFetchUserGroups = async ({ isAdmin, groupId } = {}) => {
    try {
      const params = {};
      if (!isAdmin) {
        params.userId = user.id;
      } else {
        params.groupId = groupId;
      }
      const data = await GroupService.getUserGroupsForDashboard(params);
      setUserGroups(data.data);
      setUserGroupsCount(data.totalCount);
    } catch (e) {
      console.error('Error while fetching user groups: ', e.message);
      props.addError(e);
    }
  };

  const handleJoinGroup = async ({ code }) => {
    try {
      const data = await GroupService.joinGroup({
        userId: user.id,
        groupCode: code,
      });
      setUserGroups([data.data, ...userGroups]);
    } catch (e) {
      console.error('Error joining group: ', e.message);
      props.addError(e);
    }
  };

  const handleEditUserGroup = async (data) => {
    try {
      if (!data.userId) data.userId = user.id;
      await GroupService.editUserGroup({ ...data });
      await handleFetchUserGroups();
    } catch (e) {
      console.error('Error editing user group: ', e.message);
      props.addError(e);
    }
  };

  const handleCreateUpdateGroup = async (groupInfo) => {
    try {
      let data = null;
      if (groupInfo.id) {
        data = await GroupService.editGroup(groupInfo);
      } else {
        data = await GroupService.createGroup(groupInfo);
      }
      const groups = await GroupService.getGroups({ limit: 100, offset: 0 });
      setInviteCodes(groups.data);
      return data;
    } catch (error) {
      console.log('Error edit group:', error.message);
      props.addError(error);
    }
  };

  const handleLeaveGroup = async (userGroupId) => {
    try {
      await GroupService.leaveGroup(userGroupId);
      setUserGroups(userGroups.filter((item) => item.id !== userGroupId));
    } catch (e) {
      console.error('Error leaving group: ', e.message);
      props.addError(e);
    }
  };

  const context = {
    inviteCodes,
    userGroupData,
    userGroups,
    userGroupsCount,
    setUserGroups,
    onFetchInviteCodesForPanel: handleFetchInviteCodesForPanel,
    onFetchInviteCodes: handleFetchInviteCodes,
    onFetchGroupsByOwner: handleFetchGroupsByOwner,
    onFetchUserGroup: handleFetchUserGroup,
    onFetchUserGroups: handleFetchUserGroups,
    onCreateGroupInviteCode: handleCreateUpdateGroup,
    onEditGroupInviteCode: handleCreateUpdateGroup,
    onEditUserGroup: handleEditUserGroup,
    onJoinGroup: handleJoinGroup,
    onLeaveGroup: handleLeaveGroup,
  };

  return <Provider value={context}>{props.children}</Provider>;
};

const mapStateToProps = (state) => state;
const ConectInviteCodeProvider = connect(mapStateToProps, { addError })(
  InviteCodeProvider
);

export {
  ConectInviteCodeProvider as InviteCodeProvider,
  Consumer as InviteCodeConsumer,
};
export default InviteCodeContext;
