import api from './../../utils/api';

const TransactionsService = {
  getTransactions: async({ limit, offset, type, subjectUserId, withoutPagination }) => {
    const filterData =
      `${type ? '&type=' + type : ''}${subjectUserId ? '&subjectUserId=' + subjectUserId : ''}`;

    const path = withoutPagination ?
      `/transactions/?withoutPagination=true` + filterData :
      `/transactions/?limit=${limit}&offset=${offset}` + filterData;

    return await api.promise({
      path,
      method: 'GET',
    });
  },

  createTransaction: async(data) => {
    const result = await api.promise({
      path: '/transactions',
      method: 'POST',
      body: data
    });

    return result.data;
  },

  sendMessage: async(data) => {
    const result = await api.promise({
      path: '/users/send-message',
      method: 'POST',
      body: data
    });

    return result.data;
  },

  updateTransactionById: async(id, updateData) => {
    const result = await api.promise({
      path: `/transactions/${id}`,
      method: 'PUT',
      body: updateData
    });

    return result.data;
  }
};

export default TransactionsService;
