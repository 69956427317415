import _ from "lodash";
import React from "react";

import ProvidersService from "../services/ProvidersService";
import { connect } from "react-redux";
import { addError } from "../redux/actions/request";

const ACCESS_DENIED = "ACCESS_DENIED";

const ProviderContext = React.createContext();
const { Provider, Consumer } = ProviderContext;

const initialState = {
  paginationLimit: 24,
  paginationOffset: 0,
  hasMoreItems: true,
  hasAccess: false,
  isLoaded: false,
  hasProviders: true,
  isNewList: false,
  filterParams: {},
  providers: [],
  provider: null,
  total: 0
};

class ProviderProvider extends React.Component {
  constructor(props) {
    super(props);

    this.providerAPI = ProvidersService;

    this.state = {
      ...initialState,
      handleFetchList: this.getProviders,
      handleFetchDetail: this.getProviderDetail,
      selectDetail: this.selectDetail,
      setHasAccess: this.setHasAccess,
      setInitialValues: this.setInitialValues,
      handleCheckProvider: this.checkFellowSchoolGroupMember
    };
  }

  setHasAccess = hasAccess => {
    this.setState({ hasAccess });
  };

  checkFellowSchoolGroupMember = async (providerId, userId) => {
    const result = await this.providerAPI.checkFellow(providerId, userId);
    return result.data
  }
  
  getProviders = async (filterParams, initial = false) => {
    try {
      const isNewList = !!filterParams && _.isEmpty(filterParams);
      const state = !!filterParams ? initialState : this.state;
      const filter = !filterParams ? state.filterParams : filterParams;

      if (initial) {
        this.setState({
          isLoaded: false
        });
      }

      const { paginationLimit, paginationOffset } = state;
      const params = {
        limit: paginationLimit,
        offset: paginationOffset,
        ...filter
      };
      const result = await this.providerAPI.getProviders(params);

      const newProviders = [...state.providers, ...result.data];
      const hasMoreItems = newProviders.length < result.totalCount;
      const hasProviders = !!newProviders.length;

      this.setState(state => ({
        providers: newProviders,
        hasProviders: hasProviders,
        paginationOffset: paginationOffset + initialState.paginationLimit,
        hasMoreItems: hasMoreItems,
        hasAccess: true,
        isLoaded: true,
        filterParams: filter,
        isNewList: isNewList,
        total: result.totalCount || state.total
      }));
    } catch (e) {
      e.message === ACCESS_DENIED
        ? this.setState({
            hasAccess: false,
            isLoaded: true
          })
        : this.props.addError(e);
    } finally {
      this.setState({ ...this.state, isNewList: false });
    }
  };

  setInitialValues = () => {
    this.setState({
      ...initialState
    });
  };

  getProviderDetail = async (referralLink, authorized) => {
    try {
      const providerRequest = authorized ? 'getProvider' : 'getProviderUnAuth'
      const providerScopeRequest = authorized ? 'getProviderScope' : 'providerScopeRequestUnAuth'
      const result = await this.providerAPI[providerRequest](referralLink);

      const user = result.data;

      const {isStudent, isAlum, isProfessional, isCoach} = user.identities;

      const [userSchools, userProfessional, userCoach] = await Promise.all([
        this.providerAPI[providerScopeRequest](user.id, 'withStudentSchool'),
        this.providerAPI[providerScopeRequest](user.id, 'withUserProfessional'),
        this.providerAPI[providerScopeRequest](user.id, 'withUserCoach')
      ])

      if (isStudent || isAlum) {
        user.school = userSchools.data.school;
      }
      if (isProfessional) {
        user.professional = userProfessional.data.professional;
      }
      if (isCoach) {
        user.coach = userCoach.data.coach;
      }

      this.setState(() => ({
        provider: result,
        hasAccess: true,
        isLoaded: true
      }));

      return result;
    } catch (e) {
      e.message === ACCESS_DENIED
        ? this.setState({
            hasAccess: false,
            isLoaded: true
          })
        : this.props.addError(e);
    } finally {
      this.setState({ ...this.state, isNewList: false });
    }
  };

  selectDetail = providerId => {
    return _.find(this.state.providers, { id: providerId });
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

function withProvider(Component) {
  return function ConnectedComponent(props) {
    return (
      <ProviderContext.Consumer>
        {value => <Component {...props} providerStore={value} />}
      </ProviderContext.Consumer>
    );
  };
}

const mapStateToProps = state => state;
const connectedProviderProvider = connect(mapStateToProps, { addError })(
  ProviderProvider
);

export {
  connectedProviderProvider as ProviderProvider,
  Consumer as ProviderConsumer,
  withProvider
};
export default ProviderContext;
