import api from './../../utils/api';
import queryString from 'query-string';

const ProviderService = {
  async getProviders(params) {
    const stringified = queryString.stringify(params, {
      arrayFormat: 'bracket'
    });
    return await api.promise({
      path: `/users/providers?${stringified}`,
      method: 'GET'
    });
  },

  async getProvider(referralLink) {
    return await api.promise({
      path: `/users/providers/${referralLink}`,
      method: 'GET'
    });
  },

  async getProviderUnAuth(referralLink) {
    return await api.promise({
      path: `/users/providers/view/${referralLink}`,
      method: 'GET'
    });
  },

  async getProviderScope(id, scopeId) {
    return await api.promise({
      path: `/users/providers/${id}/scope/${scopeId}`,
      method: 'GET'
    });
  },

  async providerScopeRequestUnAuth(id, scopeId) {
    return await api.promise({
      path: `/users/providers/view/${id}/scope/${scopeId}`,
      method: 'GET'
    });
  },

  async checkFellow(providerId, userId) {
    return await api.promise({
      path: `/users/providers/view/${providerId}/verification/${userId}`,
      method: 'GET'
    });
  },
};

export default ProviderService;
