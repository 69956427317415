import React from 'react';
import FavoriteService from '../services/FavoriteService';

const FavoriteContext = React.createContext();
const {Provider, Consumer} = FavoriteContext;

const FavoriteProvider = props => {
  const getProvidersAdminPanel = async () => {
    return await FavoriteService.getProvidersAdminPanel();
  }

  const getAllFavoritesProviders = async () => {
    return await FavoriteService.getAllFavoritesProviders();
  }

  const saveFavoriteProviders = async (data) => {
    return await FavoriteService.saveFavoriteProviders(data);
  }

  const getReviewsAdminPanel = async () => {
    return await FavoriteService.getReviewsAdminPanel();
  }

  const getAllFavoritesReviews = async () => {
    return await FavoriteService.getAllFavoritesReviews();
  }

  const saveFavoriteReviews = async (data) => {
    return await FavoriteService.saveFavoriteReviews(data);
  }

  const context = {
    saveFavoriteProviders,
    getAllFavoritesProviders,
    getProvidersAdminPanel,
    getReviewsAdminPanel,
    getAllFavoritesReviews,
    saveFavoriteReviews,
  };

  return <Provider value={context}>{props.children}</Provider>;
};

export {FavoriteProvider, Consumer as FavoriteConsumer};
export default FavoriteContext;
