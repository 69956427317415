import * as types from '../actions/types';
import { handleActions } from 'redux-actions';

export const xhr = handleActions({
  [types.XHR_ERROR]: (state, action) => {
    return {
      ...state,
      errors: [
        ...state.errors,
        action.error,
      ]
    };
  },
  [types.XHR_ERROR_HANDLED]: (state) => {
    return {
      ...state,
      errors: []
    };
  },
}, {
  errors: [],
});
