import api from './../../utils/api';
import queryString from 'query-string';

const ReviewService = {
  async removeReview(reviewId, userId) {
    const result = await api.promise({
      path:   `/reviews/remove/${reviewId}`,
      method: 'POST',
      body:   {
        userId
      }
    });
    
    return result.data;
  },

  async getReviewForEdit(reviewId) {
    const result = await api.promise({
      path: `/reviews/edit/${reviewId}`,
      method: 'GET'
    });

    return result.data;
  },

  async getAllReviewForUser(userId) {
    const result = await api.promise({
      path: `/reviews/${userId}`,
      method: 'GET'
    });
    return result.data;
  },

  async getAllBoughtReviewForUser(userId) {
    const result = await api.promise({
      path: `/reviews/bought/${userId}`,
      method: 'GET'
    });
    return result.data;
  },

  async getAllReviews() {
    const result = await api.promise({
      path: `/reviews/admin/`,
      method: 'GET'
    });
    return result.data;
  },

  async changeStatusReview(data) {
    const result = await api.promise({
      path: `/reviews/admin`,
      method: 'POST',
      body: data
    });

    return result.data;
  },

  async createReview(data) {
    const result = await api.promise({
      path: `/reviews/add`,
      method: 'POST',
      body: data
    });

    return result.data;
  },

  async saveEditReview(data, reviewId) {
    const result = await api.promise({
      path: `/reviews/edit/${reviewId}`,
      method: 'POST',
      body: data
    });

    return result.data;
  },

  async getAllReviewForKnowledgeBank(params) {
    const stringified = queryString.stringify(params, {
      arrayFormat: 'bracket'
    });
    return await api.promise({
      path: `/reviews/all?${stringified}`,
      method: 'GET'
    });
  },

  async getAllReviewForKnowledgeBankUnAuth(params) {
    const stringified = queryString.stringify(params, {
      arrayFormat: 'bracket'
    });
    return await api.promise({
      path: `/reviews/all/view?${stringified}`,
      method: 'GET'
    });
  },

  async rememberPayForReview(data) {
    const result = await api.promise({
      path: `/reviews/add/buy`,
      method: 'POST',
      body: data
    });

    return result.data;
  },

  async getReviewForKnowledgeBank(specialId) {
    const result = await api.promise({
      path: `/reviews/bank/${specialId}`,
      method: 'GET',
    });

    return result.data;
  },

  async getBuyStatus(data) {
    const result = await api.promise({
      path: `/reviews/status`,
      method: 'POST',
      body: data
    });

    return result.data;
  },

  async fetchReviewName(specialId) {
    const result = await api.promise({
      path: `/reviews/name/${specialId}`,
      method: 'GET'
    });

    return result.data;
  },
};

export default ReviewService;
