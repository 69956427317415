import { Toaster, Position, Intent } from '@blueprintjs/core';

const AppToaster = Toaster.create({
  position: Position.TOP_RIGHT,
});

export function showErrorToast(err) {
  AppToaster.show({
    message: err.message,
    intent: Intent.DANGER,
  });
}
