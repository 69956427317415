import api from '../../utils/api';
import queryString from 'query-string';

const GroupService = {
  async getGroups(params) {
    const stringified = queryString.stringify(params, {
      arrayFormat: 'bracket',
    });
    return await api.promise({
      path: `/groups?${stringified}`,
      method: 'GET',
    });
  },

  async getGroupsForPanel() {
    return await api.promise({
      path: `/groups/panel`,
      method: 'GET',
    });
  },

  async getGroupsByUser(body) {
    return await api.promise({
      path: `/groups/owner`,
      method: 'POST',
      body,
    });
  },

  async getUserGroup(id) {
    return await api.promise({
      path: `/groups/user/${id}`,
      method: 'GET',
    });
  },

  async getUserGroups(data) {
    return await api.promise({
      path: `/groups/user`,
      method: 'POST',
      body: data,
    });
  },

  async getUserGroupsForDashboard(data) {
    return await api.promise({
      path: `/groups/dashboard/user`,
      method: 'POST',
      body: data,
    });
  },

  async getUserGroupTags(data) {
    return await api.promise({
      path: '/groups/user/tags',
      method: 'POST',
      body: data
    })
  },

  async joinGroup(data) {
    return await api.promise({
      path: '/groups/join',
      method: 'POST',
      body: data,
    });
  },

  async editGroup(group) {
    return await api.promise({
      path: '/groups',
      method: 'PUT',
      body: group,
    });
  },

  async editUserGroup(group) {
    return await api.promise({
      path: '/groups/user',
      method: 'PUT',
      body: group,
    });
  },

  async createGroup(group) {
    return await api.promise({
      path: '/groups',
      method: 'POST',
      body: group,
    });
  },

  async getAllUsers() {
    return await api.promise({
      path: '/groups/users/all',
      method: 'GET',
    });
  },

  async leaveGroup(userGroupId) {
    return await api.promise({
      path: `/groups/leave/${userGroupId}`,
      method: 'DELETE',
    });
  },

  async export(groupId) {
    return await api.promise({
      path: `/groups/export/${groupId}`,
      method: 'GET',
    });
  },

  async exportAll(body) {
    return await api.promise({
      path: `/groups/exportAll`,
      method: 'POST',
      body,
    });
  },
};

export default GroupService;
