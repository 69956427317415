import api from './../../utils/api';
import { redirectUri } from '../constants';

const UsersService = {
  request: api.promise,

  async signUp(user) {
    return this.request({
      path: '/users',
      method: 'POST',
      body: {
        ...user
      },
    });
  },

  async checkInviteCode(code) {
    return this.request({
      path: `/users/referral/${code}`,
      method: 'GET',
    });
  },

  signIn(user) {
    return this.request({
      path: '/sessions',
      method: 'POST',
      body: user,
    });
  },

  refreshSession(refreshToken) {
    return this.request({
      path: '/sessions',
      method: 'PUT',
      body: { refreshToken },
    });
  },

  async verifyEmail(token) {
    return await api.promise({
      path: '/verifications',
      method: 'PUT',
      body: { token }
    });
  },

  async logout() {
    return this.request({
      path: '/sessions',
      method: 'DELETE',
    });
  },

  async loginLinkedIn(code, type, referralLink) {
    return this.request({
      path: '/socials/sessions/linkedin',
      method: 'POST',
      body: {
        code,
        type,
        redirectUri,
        referralLink
      }
    });
  },

  async getProfile(id = 'me') {
    return this.request({
      path: `/users/${id}`,
      method: 'GET'
    });
  },

  async getProfileScope(id = 'me', scope = '') {
    return this.request({
      path: `/users/${id}/scope/${scope}`,
      method: 'GET'
    });
  },

  async editProfile(body) {
    return this.request({
      path: '/users/me',
      method: 'PUT',
      body
    });
  },

  async resetPasswordRequest(email) {
    return this.request({
      path: '/verifications/password',
      method: 'POST',
      body: {
        email
      }
    });
  },

  async resetPassword(password, token) {
    return this.request({
      path: '/verifications/password',
      method: 'PUT',
      body: {
        password,
        token
      }
    });
  },

  async resendVerifyEmail(email) {
    return this.request({
      path: '/verifications',
      method: 'POST',
      body: {
        email,
      }
    });
  },

  setAvatar(imageId) {
    return this.request({
      path: '/users/me/avatar',
      method: 'PUT',
      body: {
        imageId
      }
    });
  },

  async setUserCompenasations(body) {
    return this.request({
      path: '/users/compensations',
      method: 'PUT',
      body
    });
  },

  async setUserAvailability(body) {
    return this.request({
      path: '/users/availability',
      method: 'PUT',
      body
    });
  },

  async checkCompletedProfile(id) {
    return this.request({
      path: `/users/has-completed/${id}`,
      method: 'GET',
    });
  },

  async checkProfileCompleted(id) {
    return this.request({
      path: `/users/check-completed/${id}`,
      method: 'GET',
    });
  },

  async checkAvailabilityProvider(referralLink) {
    return this.request({
      path: `/users/check-availability/${referralLink}`,
      method: 'GET',
    });
  },

  async requestProfileCompleteEmail(id) {
    return this.request({
      path: `/users/profile-complete/${id}`,
      method: 'GET'
    })
  },

  async getAdminKip101Data() {
    const result = await api.promise({
      path: `/users/kip/admin`,
      method: 'GET',
    });

    return result.data;
  },
};

export default UsersService;
