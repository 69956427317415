import api from './../../utils/api';
import queryString from 'query-string';

const RewardService = {
  async createReward(data) {
    const result = await api.promise({
      path: `/rewards/add`,
      method: 'POST',
      body: data,
    });

    return result.data;
  },

  async getRewardForEdit(rewardId) {
    const result = await api.promise({
      path: `/rewards/edit/${rewardId}`,
      method: 'GET',
    });

    return result.data;
  },

  async getRewards(groupId) {
    const result = await api.promise({
      path: `/rewards/${groupId}`,
      method: 'GET',
    });

    return result.data;
  },

  async getKipInventoryRewards() {
    const result = await api.promise({
      path: `/rewards/admin/inventory`,
      method: 'GET',
    });

    return result.data;
  },

  async saveEditReward(data) {
    const result = await api.promise({
      path: `/rewards/edit`,
      method: 'POST',
      body: data,
    });

    return result.data;
  },

  async getAllRewardForRewardsModule(params) {
    const stringified = queryString.stringify(params, {
      arrayFormat: 'bracket'
    });
    return await api.promise({
      path: `/rewards/all?${stringified}`,
      method: 'GET'
    });
  },

  async getRewardsFulfillment(groupId) {
    const result = await api.promise({
      path: `/rewards/fulfillments/${groupId}`,
      method: 'GET',
    });

    return result.data;
  },

  async getRewardsFulfillmentForAdmin(groupId) {
    const result = await api.promise({
      path: `/rewards/fulfillments/admin/${groupId}`,
      method: 'GET',
    });

    return result.data;
  },

  async updateFulfillmentReward(data) {
    const result = await api.promise({
      path: `/rewards/confirm`,
      method: 'POST',
      body: data,
    });

    return result.data;
  },

  async updateFulfillmentRewardForAdmin(data) {
    const result = await api.promise({
      path: `/rewards/confirm/admin`,
      method: 'POST',
      body: data,
    });

    return result.data;
  },

  async unconfirmFulfillmentReward(data) {
    const result = await api.promise({
      path: `/rewards/unconfirm`,
      method: 'POST',
      body: data,
    });

    return result.data;
  },

  async unconfirmFulfillmentRewardForAdmin(data) {
    const result = await api.promise({
      path: `/rewards/unconfirm/admin`,
      method: 'POST',
      body: data,
    });

    return result.data;
  },

  async rememberPayForReward(data) {
    const result = await api.promise({
      path: `/rewards/add/buy`,
      method: 'POST',
      body: data
    });

    return result.data;
  },

  async getBalanceForBuyReward(userId) {
    const result = await api.promise({
      path: `/rewards/balance/${userId}`,
      method: 'GET',
    });

    return result.data;
  },

  async getMaxPrice(userId) {
    const result = await api.promise({
      path: `/rewards/price/${userId}`,
      method: 'GET',
    });

    return result.data;
  },

  async fetchRewardName(rewardId) {
    const result = await api.promise({
      path: `/rewards/find/${rewardId}`,
      method: 'GET',
    });

    return result.data;
  },

  async exportRewardsTable() {
    return await api.promise({
      path: `/rewards/admin/export`,
      method: 'GET',
    });
  },

};

export default RewardService;
