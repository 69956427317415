import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createLogger from 'redux-logger';

import { rootReducer } from './redux/root';

export default function configureStore(initialState = {}) {
  const middleware = [thunkMiddleware];

  if (process.env.REDUX_LOGGER) {
    middleware.push(createLogger());
  }

  const composeEnhancers =
    // eslint-disable-no-undef
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware)),
  );

  return store;
}
