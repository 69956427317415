import api from '../../../utils/api';
import * as types from './types';
import * as Sentry from "@sentry/react";

export function request(config) {
  return async dispatch => {
    try {
      return await api.promise(config);
    } catch (e) {
      dispatch({
        type: types.XHR_ERROR,
        error: e
      });
    }
  };
}

export function cleanErrorsCache() {
  return dispatch => {
    dispatch({
      type: types.XHR_ERROR_HANDLED
    });
  };
}

export function addError(e) {
  let errorMsg = e;
  Sentry.captureException(e);
      switch (e.message) {
        case 'connect ETIMEDOUT':
          errorMsg.message = 'We are currently experiencing heavy traffic.  Please wait a bit longer or try back at a later time.  Apologies!'
          break;
        case 'Failed to fetch':
          errorMsg.message = 'We are currently experiencing heavy traffic.  Please wait a bit longer or try back at a later time.  Thank you for your patience!'
          break;
        case 'Gateway timeout error':
          errorMsg.message = 'We are currently experiencing heavy traffic.  Please wait a bit longer or try back at a later time.  Thanks for your patience!'
          break;
        default:
          break;
      }
  return dispatch =>
    dispatch({
      type: types.XHR_ERROR,
      error: errorMsg
    });
}
