import React, { useReducer, useState } from 'react';
import TransactionsService from '../services/TransactionsService';

const ConnectionContext = React.createContext();
const { Provider, Consumer } = ConnectionContext;
const transactionApi = TransactionsService;

const ConnectionProvider = props => {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'setConnections':
        return action.connections;
      case 'addConnections':
        return action.connections && action.connections.length ? [...state, ...action.connections] : state;
      case 'pushConnection':
        return action.connection ? [action.connection, ...state] : state;
      case 'updateConnection': {
        const updatedList = state;
        updatedList.forEach((el, index, arr) => {
          if (el.id === action.connection.id) {
            arr[index] = action.connection;
          }
        });
        return [...updatedList];
      }
    }
  };
  const [connections, dispatch] = useReducer(reducer, []);
  const [hasConnectionsWithSubject, setHasConnectionsWithSubject] = useState(false);
  const [loaderParams, setLoaderParams] = useState({
    limit: 20,
    offset: 0,
    hasMoreItems: true,
    isLoaded: false,
    total: 0
  });

  const handleFetchConnections = async() => {
    const result = await transactionApi.getTransactions({
      limit: loaderParams.limit,
      offset: loaderParams.offset,
      type: 'New Connection'
    });
    updateLoaderParams(result);

    const data = result.data;
    if (loaderParams.offset === 0 && data[0]) {
      dispatch({ type: 'setConnections', connections: data });
    } else {
      dispatch({ type: 'addConnections', connections: result.data });
    }
  };

  const handleUpdateConnection = async(id, updateData) => {
    await transactionApi.updateTransactionById(id, updateData);
  };

  const handleCheckConnectionsWithSubject = async(subjectUserId) => {
    const result = await transactionApi.getTransactions({
      limit: 1,
      offset: 0,
      type: 'New Connection',
      subjectUserId
    });
    if (result.totalCount > 0) setHasConnectionsWithSubject(true);
  };

  const handlePushConnection = async(connection) => {
    dispatch({ type: 'pushConnection', connection });
  };

  const updateLoaderParams = (result) => {
    setLoaderParams({
      ...loaderParams,
      offset: loaderParams.offset + loaderParams.limit,
      hasMoreItems: loaderParams.limit <= result.data.length,
      total: result.totalCount || loaderParams.total
    });
  };

  const handleInitialFetchConnections = async() => {
    dispatch({ type: 'setConnections', connections: [] });
    const result = await transactionApi.getTransactions({
      limit: 20,
      offset: 0,
      type: 'New Connection'
    });
    updateLoaderParams(result);

    const data = result.data;
    dispatch({ type: 'setConnections', connections: JSON.parse(JSON.stringify(data)) });
  };

  const sendMessage = async(data) => {
    await transactionApi.sendMessage(data);
  };

  const context = {
    connections,
    hasConnectionsWithSubject,
    loaderParams,
    onInitialFetchConnections: handleInitialFetchConnections,
    onFetchConnections: handleFetchConnections,
    onUpdateConnection: handleUpdateConnection,
    onPushConnection: handlePushConnection,
    onCheckConnectionsWithSubject: handleCheckConnectionsWithSubject,
    sendMessage
  };

  return <Provider value={context}>{props.children}</Provider>;
};

export { ConnectionProvider, Consumer as TransactionConsumer };
export default ConnectionContext;

