import api from './../../utils/api';

const FavoriteService = {
  async getProvidersAdminPanel() {
    const result = await api.promise({
        path: `/favorites/providers/all`,
        method: 'GET',
      });

    return result.data;
  },

  async getAllFavoritesProviders() {
    const result = await api.promise({
        path: `/favorites/providers`,
        method: 'GET',
      });

    return result.data;
  },

  async saveFavoriteProviders(data) {
    const result = await api.promise({
        path: `/favorites/providers`,
        method: 'POST',
        body: data
      });

    return result.data;
  },

  async getReviewsAdminPanel() {
    const result = await api.promise({
        path: `/favorites/reviews/all`,
        method: 'GET',
      });

    return result.data;
  },

  async getAllFavoritesReviews() {
    const result = await api.promise({
        path: `/favorites/reviews`,
        method: 'GET',
      });

    return result.data;
  },

  async saveFavoriteReviews(data) {
    const result = await api.promise({
        path: `/favorites/reviews`,
        method: 'POST',
        body: data
      });

    return result.data;
  },

}

export default FavoriteService