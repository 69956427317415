import React from 'react';

import cookie, { COOKIE_KEY_REFRESH_TOKEN } from '../../utils/cookie';
import storage, { USER_STORAGE_KEY } from '../../utils/localStorage';

import USERS from '../fixtures/users.json';
import UsersService from "../services/UsersService";
import { USER_ROLES } from "../constants/userRoles";
import { connect } from 'react-redux';
import { request } from "../redux/actions/request";
import SchoolsService from '../services/SchoolsService';
import _ from 'lodash';

const UserContext = React.createContext();
const { Provider, Consumer } = UserContext;

const initialUser = {
  identities: {},
};

class UserProvider extends React.Component {
  constructor(props) {
    super(props);

    this.storageKey = USER_STORAGE_KEY;

    const { request } = this.props;
    this.userApi = UsersService;
    this.userApi.request = request;

    this.state = {
      bootstrap: false,
      token: undefined,
      type: null,
      user: initialUser,
      owner: false,
      tags: [],
      groupForTags: undefined,
      isLoggedIn: this.isLoggedIn,
      hasIdentity: this.hasIdentity,
      isBootstrapped: this.isBootstrapped,
      isGroupOwner: this.isGroupOwner,
      onBootstrap: this.handleBootstrap,
      onRegister: this.handleRegister,
      onLogin: this.handleLogin,
      onLoginLN: this.handleLoginLN,
      onLogout: this.handleLogout,
      setType: this.handleTypeChange,
      onVerifyEmail: this.handleEmailVerify,
      onVerifyEmailResend: this.handleEmailVerifyResend,
      onConfirmDialogClose: this.handleConfirmDialogClose,
      onConfirmGroupDialogClose: this.handleConfirmGroupDialogClose,
      actualizeUserInfo: this.getProfile,
      editProfile: this.handleEditProfile,
      setSchools: this.setSchools,
      handleUpdateUserData: this.handleUpdateUserData,
      handleReferralCodeCheck: this.handleInviteCodeValidation,
      checkCompletedProfile: this.checkCompletedProfile,
      checkProfileComplete: this.checkProfileComplete,
      setCoach: this.setCoachValue,
      setProfessional: this.setProfessionalValue,
      checkAvailabilityProvider: this.checkAvailabilityProvider,
      requestProfileCompleteEmail: this.requestProfileCompleteEmail,
      onSaveTagsAfterRegister: this.handleSaveTagsAfterRegister,
      balance: 101,
      verifyEmail: {
        isVerifying: true,
        isVerified: false,
        verificationError: null,
      },
      passwordReset: {
        error: undefined,
        message: undefined,
        intent: 'none',
        email: undefined,
        submitted: undefined,
        resetSuccess: undefined,
        resetRequestSuccess: undefined,
        token: undefined,
        onResetPassword: this.handleResetPassword,
        onResetPasswordRequest: this.handleResetPasswordRequest,
        onResendResetLink: this.handleResendResetLink,
        onVerifyResetToken: this.handleVerifyResetToken,
        onUpdatePasswordReset: this.handleResetPasswordState,
      },
      registerMiddleware: {
        showDialog: false,
        token: undefined,
        user: initialUser,
      },
      registerTagsMiddleware: {
        showTagsDialog: false,
        user: initialUser,
      }
    }
  }

  isGroupOwner = () => {
    return this.state.user.groups && !!this.state.user.groups.length;
  };

  checkProfileComplete = async (userId) => {
    return this.userApi.checkProfileCompleted(userId);
  };

  isLoggedIn = () => {
    return !!this.state.token;
  };

  hasIdentity = () => {
    const { isStudent, isAlum, isCoach, isProfessional } = this.state.user.identities;
    return isStudent || isAlum || isCoach || isProfessional;
  };

  isBootstrapped = () => {
    return !!this.state.bootstrap;
  };

  handleBootstrap = async () => {
    if (cookie.hasToken()) {
      this.setState(() => ({
        token: cookie.getToken(),
      }));
    }

    const user = storage.getItem(this.storageKey);

    storage.removeItem('has_watched_video');

    this.setState(() => ({
      bootstrap: true,
      user: {
        ...user
      },
    }));
  };

  handleInviteCodeValidation = async (value) => {
    try {
      return await this.userApi.checkInviteCode(value);
    } catch (err) {
      console.log('Referral code error:', err);
    }
  };

  handleUpdateUserData = userData => {
    const storageUser = storage.getItem(this.storageKey);
    const user = { ...storageUser, ...userData };

    storage.setItem(this.storageKey, user);
    this.setState({ user });
  };

  handleRegister = async (user, actions) => {
    delete user.passwordRepeat;
    const result = await this.userApi.signUp(user);
    if (result) {
      cookie.setCookie(COOKIE_KEY_REFRESH_TOKEN, result.data.session.refreshToken);
      cookie.setToken(result.data.session.accessToken);
      storage.setItem(this.storageKey, result.data.user);

      this.setState(prevState => ({
        ...prevState,
        registerMiddleware: {
          token: result.data.session.accessToken,
          user: {
            ...USERS[0],
            ...result.data.user
          },
          showDialog: true,
        }
      }));

      if (result.data.tags && result.data.tags.data){
        this.setState(prevState => ({
          ...prevState,
          tags: result.data.tags.data,
          groupForTags: {groupName: result.data.tags.name, groupId: result.data.tags.groupId}
        }))
      }

    }
    actions.setSubmitting(false)
  };

  handleLogin = async (user, actions) => {
    try {
      const result = await this.userApi.signIn(user);
      if (result) {
        const newGroups = _.uniqBy([...result.data.user.groups, ...result.data.user.rewardOwnerGroups], 'id')
        const newUser = result.data.user
        newUser.groups = newGroups
        this.storeUser(newUser, result.data.session.accessToken);
        cookie.setCookie(COOKIE_KEY_REFRESH_TOKEN, result.data.session.refreshToken);
        this.checkCompletedProfile(result.data.user.id)
      }
      actions.setSubmitting(false);
    } catch (e) {
      console.log('Login error: ', e);
    }
  };

  handleLogout = () => {
    this.userApi.logout()
      .finally(() => {
        cookie.removeToken();
        cookie.removeCookie(COOKIE_KEY_REFRESH_TOKEN);
        storage.removeItem(this.storageKey);
        storage.removeItem('has_watched_video');
        this.setState({
          token: undefined,
          user: initialUser,
        });
      });
  };

  handleResetPasswordRequest = async ({ email }, actions) => {
    const success = await this.userApi.resetPasswordRequest(email);
    if (success) {
      this.setState(prevState => ({
        passwordReset: {
          ...prevState.passwordReset,
          email,
          submitted: true,
        },
      }));
    }
    actions.setSubmitting(false);
  };

  handleResetPassword = async ({ password }, actions) => {
    const success = await this.userApi.resetPassword(password, this.state.passwordReset.token);
    if (success) {
      this.setState(prevState => ({
        ...prevState,
        passwordReset: {
          ...prevState.passwordReset,
          submitted: true,
        },
      }));
    }
    actions.setSubmitting(false);
  };

  handleResetPasswordState = props => {
    this.setState(prevState => ({
      ...prevState,
      passwordReset: {
        ...prevState.passwordReset,
        ...props
      }
    }))
  };

  handleResendResetLink = async () => {
    const { email } = this.state.passwordReset;
    if (email) {
      const success = await this.userApi.resetPasswordRequest(email);

      if (success) {
        this.setState(prevState => ({
          ...prevState,
          passwordReset: {
            ...prevState.passwordReset,
            message: 'Successfully sent reset link',
            intent: 'success',
          },
        }));
      }
    }
  };

  handleVerifyResetToken = token => {
    this.setState(prevState => ({
      ...prevState,
      passwordReset: {
        ...prevState.passwordReset,
        token,
      }
    }))
  };

  handleLoginLN = async (code, type, referralLink = '') => {
    try {
      // let type = storage.getItem('linkedin_user_type') || USER_ROLES.PROVIDER;
      // let referralLink = storage.getItem('linkedin_user_code') || '';
      const result = await this.userApi.loginLinkedIn(code, type || USER_ROLES.PROVIDER, referralLink);
      if (result) {
        this.storeUser(result.data.user, result.data.session.accessToken);
        cookie.setCookie(COOKIE_KEY_REFRESH_TOKEN, result.data.session.refreshToken);
        this.checkCompletedProfile(result.data.user.id);
        storage.removeItem('linkedin_user_code');
        storage.removeItem('linkedin_user_type');
      }
      return result;
    } catch (e) {
      console.log('LinkedIn error: ', e);
    }
  };

  handleTypeChange = (type, referralLink) => {
    return () => {
      storage.setItem('linkedin_user_type', type);
      storage.setItem('linkedin_user_code', referralLink);
      this.setState(prevState => ({
        ...prevState,
        type
      }));
    }
  };

  handleEmailVerify = (token) => {
    this.userApi.verifyEmail(token)
      .then(() => {
        this.setState((prevState) => {
          return {
            ...prevState,
            verifyEmail: {
              ...prevState.verifyEmail,
              isVerifying: false,
              isVerified: true,
            }
          }
        })
      })
      .catch(err => {
        this.setState((prevState) => {
          return {
            ...prevState,
            verifyEmail: {
              verificationError: err,
              isVerifying: false,
              verified: false,
            }
          }
        })
      });
  };

  handleEmailVerifyResend = () => {
    return this.userApi.resendVerifyEmail(this.state.user.email);
  };

  handleConfirmDialogClose = () => {
    if (this.state.tags && this.state.tags.length) {
      this.setState(prevState => ({
        ...prevState,
        user: prevState.registerMiddleware.user,
        registerMiddleware: {
          token: prevState.registerMiddleware.token,
          user: initialUser,
          showDialog: false,
        }
      }));
      this.setState(prevState => ({
        ...prevState,
        registerTagsMiddleware: {
          showTagsDialog: true,
        }
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        user: prevState.registerMiddleware.user,
        token: prevState.registerMiddleware.token,
        registerMiddleware: {
          token: undefined,
          user: initialUser,
          showDialog: false,
        }
      }));
    }
  };

  handleConfirmGroupDialogClose = () => {
    this.setState(prevState => ({
      ...prevState,
      token: prevState.registerMiddleware.token,
      registerTagsMiddleware:{
        showTagsDialog: false,
      },
      registerMiddleware: {
        token: undefined,
        user: initialUser,
      }
    }));
  };

  storeUser = (user, token) => {
    cookie.setToken(token);
    storage.setItem(this.storageKey, user);

    this.setState(() => ({
      token: token,
      user: {
        ...user,
      },
    }));
  };

  getProfile = async () => {
    const result = await this.userApi.getProfile();
    if (result) {
      const user = result.data;
      const [userSchools, userProfessional, userCoach] = await Promise.all([
        this.userApi.getProfileScope('me', 'withStudentSchool'),
        this.userApi.getProfileScope('me', 'withUserProfessional'),
        this.userApi.getProfileScope('me', 'withUserCoach')
      ]);
      user.school = userSchools.data.school;
      user.professional = userProfessional.data.professional;
      user.coach = userCoach.data.coach;
      this.storeUser(user, this.state.token);
    }
    return result.data
  };

  getEditingShape(values) {
    let data = {
      firstName: values.firstName,
      lastName: values.lastName,
      headline: values.headline,
      summary: values.summary,
      location: values.location ? {
        id: values.location.id,
        name: values.location.name,
      } : null,
      identities: values.identities,
    };

    if (typeof values.type === 'number') {
      data.type = values.type;
    }

    return data;
  }

  handleEditProfile = async (values, actions) => {
    return await this.userApi.editProfile(this.getEditingShape(values));
    // Fix blinking of profile validation no need to store user because we are sending actual data to server
    // Yes this is optimistic update.
    // this.storeUser(values, this.state.token);
  };

  checkCompletedProfile = (id) => this.userApi.checkCompletedProfile(id);

  saveUserProfile = () => {
    return this.userApi.editProfile(this.getEditingShape(this.state.user));
  };

  setCoachValue = coachInfo => {
    const user = {...this.state.user};
    user.coach = coachInfo;
    this.storeUser(user, this.state.token);
  };

  setSchools = async schools => {
    const user = {...this.state.user};
    user.school = schools;
    this.storeUser(user, this.state.token);
    return await SchoolsService.addSchools(schools);
  };

  setProfessionalValue = professionalInfo => {
    const user = {...this.state.user};
    user.professional = professionalInfo;
    this.storeUser(user, this.state.token);
  };

  checkAvailabilityProvider = (id) => this.userApi.checkAvailabilityProvider(id);

  requestProfileCompleteEmail = id => this.userApi.requestProfileCompleteEmail(id);

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

function withUser(Component) {
  return function ConnectedComponent(props) {
    return (
      <UserContext.Consumer>
        {user => <Component {...props} userStore={user} />}
      </UserContext.Consumer>
    );
  };
}

const mapStateToProps = (state) => state;
const connectedUserProvider = connect(mapStateToProps, { request })(UserProvider);

export { connectedUserProvider as UserProvider, Consumer as UserConsumer, withUser };
export default UserContext;
