import React, { useReducer, useState } from 'react';
import TransactionsService from '../services/TransactionsService';

const CreditContext = React.createContext();
const { Provider, Consumer } = CreditContext;
const transactionApi = TransactionsService;

const defaultLoaderParams = {
  limit: 20,
  offset: 0,
  hasMoreItems: true,
  isLoaded: false,
  total: 0
};

const CreditProvider = props => {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'setCredits':
        return action.credits;
      case 'addCredits':
        return action.credits && action.credits.length ? [...state, ...action.credits] : state;
      case 'pushCredit': {
        return action.credit ? [action.credit, ...state] : state;
      }
      case 'updateCredit': {
        const updatedList = state;
        updatedList.forEach((el, index, arr) => {
          if (el.id === action.credit.id) {
            arr[index] = action.credit;
          }
        });
        return [...updatedList];
      };
      case 'clearCredit':
        return [];
    }
  };
  const [credits, dispatch] = useReducer(reducer, []);
  const [balance, setBalance] = useState(0);
  const [loaderParams, setLoaderParams] = useState(defaultLoaderParams);
  const [referralsCount, setReferralsCount] = useState(0);

  const handleFetchCredits = async(type) => {
    const result = await transactionApi.getTransactions({
      limit: loaderParams.limit,
      offset: loaderParams.offset,
      type
    });
    updateLoaderParams(result);

    const data = result.data;
    if (loaderParams.offset === 0 && data[0]) {
      dispatch({ type: 'setCredits', credits: data });
      setBalance(data[0].balance);
    } else {
      dispatch({ type: 'addCredits', credits: data });
    }
  };

  const handleCreateCredit = async(credit) => {
    const newCredit = await transactionApi.createTransaction(credit);
    dispatch({ type: 'pushCredit', credit: newCredit });
    return newCredit;
  };

  const handleUpdateCredit = async(id, updateData) => {
    await transactionApi.updateTransactionById(id, updateData);
  };

  const handleFetchReferralsCount = async(userId) => {
    const result = await transactionApi.getTransactions({
      type: 'Referral',
      withoutPagination: true
    });
    const data = result.data;
    const filteredData = data.filter(item => item.userId === userId);
    setReferralsCount(filteredData.length);
  };

  const updateLoaderParams = (result) => {
    setLoaderParams({
      ...loaderParams,
      offset: loaderParams.offset + loaderParams.limit,
      hasMoreItems: loaderParams.limit <= result.data.length,
      total: result.totalCount || loaderParams.total
    });
  };

  const handleFetchLastCredit = async() => {
    const result = await transactionApi.getTransactions({
      limit: 1,
      offset: 0
    });
    if (result.data && result.data[0]) setBalance(result.data[0].balance);
  };

  const setInitialValue = () => {
    setLoaderParams(defaultLoaderParams);
  }

  const handleClearCredit = () => {
    dispatch({ type: 'clearCredit' });
  }

  const context = {
    credits,
    balance,
    referralsCount,
    loaderParams,
    onFetchCredits: handleFetchCredits,
    onCreateCredit: handleCreateCredit,
    onUpdateCredit: handleUpdateCredit,
    onFetchLastCredit: handleFetchLastCredit,
    onFetchReferralsCount: handleFetchReferralsCount,
    onClearCredit: handleClearCredit,
    setInitialValue: setInitialValue
  };

  return <Provider value={context}>{props.children}</Provider>;
};

function withCredits(Component) {
  return function ConnectedComponent(props) {
    return (
      <CreditContext.Consumer>
        {value => <Component {...props} creditStore={value} />}
      </CreditContext.Consumer>
    );
  };
}

export { CreditProvider, Consumer as TransactionConsumer, withCredits };
export default CreditContext;

