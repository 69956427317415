import React from 'react';
import TagsService from '../services/TagsService';

const TagsContext = React.createContext();
const {Provider, Consumer} = TagsContext;

const TagsProvider = props => {
  const getAllTagsForGroup = async (groupId) => {
    return await TagsService.getAllTagsForGroup(groupId);
  }

  const removeTag = async (groupId, tagId) => {
    return await TagsService.removeTag(groupId, tagId);
  }

  const addTag = async (groupId, tag) => {
    return await TagsService.addTagToGroup(groupId, tag);
  }

  const removeAllTags = async (groupId) => {
    return await TagsService.removeAllTags(groupId);
  }

  const importTags = async (groupId, file) => {
    return await TagsService.importTags(groupId, file);
  }

  const context = {
    getAllTagsForGroup,
    removeTag,
    addTag,
    removeAllTags,
    importTags
  };

  return <Provider value={context}>{props.children}</Provider>;
};

export {TagsProvider, Consumer as TagsConsumer};
export default TagsContext;
