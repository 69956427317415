import React, { useContext, useState } from 'react';
import RewardService from '../services/RewardService';
import TransactionsService from '../services/TransactionsService';
import UsersService from '../services/UsersService';
import UserContext from './UserContext';

const RewardContext = React.createContext();
const { Provider, Consumer } = RewardContext;

const RewardProvider = (props) => {
  const [groupRewards, setGroupRewards] = useState([]);
  const [groupFulfillmentRewards, setGroupFulfillmentRewards] = useState([]);
  const [balanceRewardBuyer, setBalanceRewardBuyer] = useState(0);

  const { user } = useContext(UserContext);

  const createReward = async (data) => {
    const newData = await RewardService.createReward(data);
    setGroupRewards(newData);
  };

  const saveEditReward = async (data) => {
    const newData = await RewardService.saveEditReward(data);
    setGroupRewards(newData);
  };

  const getRewards = async (groupId) => {
    return await RewardService.getRewards(groupId);
  };

  const getRewardForEdit = async (rewardId) => {
    return await RewardService.getRewardForEdit(rewardId);
  };

  const getRewardsFulfillment = async (groupId) => {
    return await RewardService.getRewardsFulfillment(groupId);
  };

  const getRewardsFulfillmentForAdmin = async (groupId) => {
    return await RewardService.getRewardsFulfillmentForAdmin(groupId);
  };

  const getBalanceForBuyReward = async () => {
    const balance = await RewardService.getBalanceForBuyReward(user.id);
    setBalanceRewardBuyer(balance.balance)
    return balance.balance;
  };

  const fetchRewardName = async (rewardId) => {
    const name = await RewardService.fetchRewardName(rewardId);
    return name.name;
  }

  const unconfirmFulfillmentReward = async (data) => {
    const newData = await RewardService.unconfirmFulfillmentReward(data);
    setGroupFulfillmentRewards(newData);
  }

  const unconfirmFulfillmentRewardForAdmin = async (data) => {
    const newData = await RewardService.unconfirmFulfillmentRewardForAdmin(data);
    setGroupFulfillmentRewards(newData);
  }

  const payReward = async (data) => {
    await TransactionsService.createTransaction({
      userId: user.id,
      eventName: data.eventName,
      price: data.price,
      rewardSpecialName: data.rewardName,
      rewardGroupId: data.groupId,
    });
    if (data.groupId === -1) {
      const adminData = await UsersService.getAdminKip101Data();
      await RewardService.rememberPayForReward({
        recipientId: user.id,
        userFName: user.firstName,
        userLName: user.lastName,
        userEmail: user.email,
        rewardId: data.rewardId,
        groupId: data.groupId,
        rewardName: data.rewardName,
        groupName: 'KIP101',
        rewardsOwnerEmail: adminData.email,
        rewardsOwnerName: adminData.firstName,
      });
    } else {
      await RewardService.rememberPayForReward({
        recipientId: user.id,
        userFName: user.firstName,
        userLName: user.lastName,
        userEmail: user.email,
        rewardId: data.rewardId,
        groupId: data.groupId,
        rewardName: data.rewardName,
        groupName: data.groupName,
        rewardsOwnerEmail: data.rewardsOwnerEmail,
        rewardsOwnerName: data.rewardsOwnerName,
    });
    }
    
  };

  const updateFulfillmentReward = async (data) => {
    const updatedFulfillmentRewards = await RewardService.updateFulfillmentReward(
      data
    );
    setGroupFulfillmentRewards(updatedFulfillmentRewards);
  };

  const updateFulfillmentRewardForAdmin = async (data) => {
    const updatedFulfillmentRewards = await RewardService.updateFulfillmentRewardForAdmin(
      data
    );
    setGroupFulfillmentRewards(updatedFulfillmentRewards);
  };

  const getKipInventoryRewards = async () => {
    return await RewardService.getKipInventoryRewards();
    return {}
  };

  const context = {
    createReward,
    saveEditReward,
    groupRewards,
    getRewards,
    getRewardForEdit,
    getRewardsFulfillment,
    payReward,
    updateFulfillmentReward,
    groupFulfillmentRewards,
    getBalanceForBuyReward,
    balanceRewardBuyer,
    fetchRewardName,
    unconfirmFulfillmentReward,
    getKipInventoryRewards,
    getRewardsFulfillmentForAdmin,
    updateFulfillmentRewardForAdmin,
    unconfirmFulfillmentRewardForAdmin,
  };

  return <Provider value={context}>{props.children}</Provider>;
};

export { RewardProvider, Consumer as RewardConsumer };
export default RewardContext;
