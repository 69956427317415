import React, { useState } from 'react';
import { conversationApi } from '../../api';

const ConversationContext = React.createContext();
const { Provider, Consumer } = ConversationContext;

const ConversationProvider = props => {
  const [activeConversation, setActiveConversation] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [messages, setMessages] = useState([]);

  const handleFetchConversations = async() => {
    const data = await conversationApi.getConversations();
    setConversations(data);
  };

  const handleFetchMessages = async conversationId => {
    setActiveConversation(conversationId);
    const data = await conversationApi.getMessages(
      parseInt(conversationId, 10),
    );
    setMessages(data);
  };

  const setActive = id => setActiveConversation(id);

  const handleSendMessage = async({ message, user }, actions) => {
    await conversationApi.createMessage(message, user);
    // Update State
    actions.setSubmitting(false);
  };

  const handleCreateConversation = async(provider, user) => {
    const conversation = await conversationApi.createConversation(
      provider,
      user,
    );
    return conversation;
  };

  const context = {
    activeConversation,
    conversations,
    messages,
    onFetchConversations: handleFetchConversations,
    onCreateConversation: handleCreateConversation,
    onFetchMessages: handleFetchMessages,
    onSendMessage: handleSendMessage,
  };

  return <Provider value={context}>{props.children}</Provider>;
};

export { ConversationProvider, Consumer as ConversationConsumer };
export default ConversationContext;
