import { FocusStyleManager } from '@blueprintjs/core';

import 'normalize.css';
import 'flexboxgrid';
import './config';

// Import global (non-css-modules) styles here
import './blueprint.scss';
import './global.scss';

import './www/';

// http://blueprintjs.com/docs/v2/#core/accessibility.focus-management
FocusStyleManager.onlyShowFocusOnTabs();
