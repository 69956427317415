import { combineReducers } from 'redux';

import config from '../../shared/redux/config';
import { xhr } from './reducers/xhr';

export const appReducer = combineReducers({
  config,
  xhr,
});

export const rootReducer = (state, action) => {
  switch (action.type) {
    default:
      return appReducer(state, action);
  }
};
