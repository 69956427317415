import React, { useContext, useState } from 'react';
import ReviewService from '../services/ReviewService';
import TransactionsService from '../services/TransactionsService';
import UserContext from './UserContext';

const ReviewContext = React.createContext();
const { Provider, Consumer } = ReviewContext;

const ReviewProvider = (props) => {
  const { user } = useContext(UserContext);
  const [userReviews, setUserReviews] = useState([]);
  const [userBoughtReviews, setUserBoughtReviews] = useState([]);
  const [allUsersReviews, setAllUsersReviews] = useState([]);
  const removeReview = async (reviewId) => {
    const data = await ReviewService.removeReview(reviewId, user.id);
    setUserReviews(data);
  };

  const getReviewForEdit = async (reviewId) => {
    return await ReviewService.getReviewForEdit(reviewId);
  };

  const getAllReviewForUser = async () => {
    const data = await ReviewService.getAllReviewForUser(user.id);
    setUserReviews(data);
  };

  const getAllBoughtReviewForUser = async () => {
    const data = await ReviewService.getAllBoughtReviewForUser(user.id)
    setUserBoughtReviews(data);
  };

  const getAllReviews = async () => {
    const data = await ReviewService.getAllReviews();
    setAllUsersReviews(data);
  };

  const createReview = async (data) => {
    data.userId = user.id
    const newData = await ReviewService.createReview(data);
    setUserReviews(newData)
  };

  const saveEditReview = async (data, reviewId) => {
    data.userId = user.id
    const newData = await ReviewService.saveEditReview(data, reviewId);
    setUserReviews(newData)
  }

  const payReview = async (data) => {
    await TransactionsService.createTransaction({userId: user.id, eventName: data.eventName, reviewSpecialId: data.reviewSpecialId})
    await ReviewService.rememberPayForReview({userId: user.id, reviewId: data.reviewId})
  }

  const fetchReviewName = async (specialId) => {
    return await ReviewService.fetchReviewName(specialId)
  }

  const changeStatusReview = async (data) => {
    if (!data.creditReceived && data.status === 4){
      await TransactionsService.createTransaction({userId: data.userId, eventName: data.eventName, reviewSpecialId: data.specialId})
    }
    const reviews = await ReviewService.changeStatusReview({specialId: data.specialId, statusReview: data.status})
    setAllUsersReviews(reviews)
  }

  const context = {
    userReviews,
    userBoughtReviews,
    allUsersReviews,
    removeReview,
    getReviewForEdit,
    getAllReviews,
    getAllReviewForUser,
    getAllBoughtReviewForUser,
    createReview,
    saveEditReview,
    payReview,
    fetchReviewName,
    changeStatusReview,
  };

  return <Provider value={context}>{props.children}</Provider>;
};

export { ReviewProvider, Consumer as ReviewConsumer };
export default ReviewContext;
