import api from './../../utils/api';

const TagsService = {
  async getAllTagsForGroup(groupId) {
    const result = await api.promise({
      path: `/tags/all/${groupId}`,
      method: 'GET'
    });

    return result.data;
  },

  async removeTag(groupId, tagId) {
    const result = await api.promise({
      path:   `/tags/remove/${groupId}`,
      method: 'POST',
      body:   {
        tagId
      }
    });

    return result.data;
  },

  async addTagToGroup(groupId, tag) {
    const result = await api.promise({
      path: `/tags/add/${groupId}`,
      method: 'POST',
      body: tag
    })

    return result.data;
  },

  async removeAllTags(groupId) {
    const result = await api.promise({
      path: `/tags/remove/${groupId}/all`,
      method: 'DELETE'
    });

    return result.data;
  },

  async importTags(groupId, file) {
    const result = await api.promise({
      path: `/tags/import/${groupId}`,
      method: 'POST',
      form: {
        tagsList: file
      }
    })

    return result.data;
  },

  async getAllTagsForUserInGroup(userId, groupId) {
    const result = await api.promise({
      path: '/tags/user/all',
      method: 'POST',
      body: {
        userId,
        groupId
      }
    })

    return result.data;
  }
};

export default TagsService;
