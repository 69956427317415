import Debug from 'debug';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from './App';
import configureStore from './configure-store';

if (process.env.NODE_ENV === 'development') {
  Debug.enable('www,www:*');
}
// eslint-disable-no-console
console.debug = Debug('www');

// Initialize Store
const store = configureStore();

// Initialize Sentry
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://595455dd64fd44be8e09ef8a7a694029@o647271.ingest.sentry.io/5759611",
    integrations: [new Integrations.BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

// Render Client
ReactDOM.render(
  <Provider store={store} key="provider">
    <App />
  </Provider>,
  // eslint-disable-no-undef
  document.getElementById('root')
);
