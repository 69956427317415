import _ from 'lodash';
import CONVERSATIONS from '../www/fixtures/conversations.json';
import MESSAGES from '../www/fixtures/messages.json';

async function createConversation(conversation) {
  // const { data } = await api.promise({
  //   method: 'POST',
  //   path: `/conversations`,
  //   body: conversation,
  // });
  // return data;
  return Promise.resolve(conversation);
}

async function getConversations(userId) {
  // const { data } = await api.promise({
  //   method: 'GET',
  //   path: `/conversations/${userId}`,
  // });
  // return data;
  return Promise.resolve(CONVERSATIONS);
}

async function createMessage(message) {
  // const { data } = await api.promise({
  //   method: 'POST',
  //   path: `/messages`,
  //   body: message,
  // });
  // return data;
  return Promise.resolve(message);
}

async function getMessages(conversationId) {
  // const { data } = await api.promise({
  //   method: 'GET',
  //   path: `/messages/${conversationId}`,
  // });
  // return data;
  const messages = _.filter(MESSAGES, { conversationId });
  return Promise.resolve(messages);
}

export default {
  getConversations,
  createConversation,
  getMessages,
  createMessage,
};
