import api from './../../utils/api';

const SchoolsService = {
  search: async(value) => {
    const result = await api.promise({
      path: `/autocomplete/schools?q=${encodeURIComponent(value)}`,
      method: 'GET'
    });
    return result.data;
  },

  addSchools: async(collection) => {
    const result = await api.promise({
      path: '/schools',
      method: 'POST',
      body: collection
    });

    return result.data;
  },
};

export default SchoolsService;
