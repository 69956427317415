import _ from "lodash";
import React from "react";

import ReviewService from "../services/ReviewService";
import { connect } from "react-redux";
import { addError } from "../redux/actions/request";

const ACCESS_DENIED = "ACCESS_DENIED";

const KnowledgeBankContext = React.createContext();
const { Provider, Consumer } = KnowledgeBankContext;

const initialState = {
  paginationLimit: 24,
  paginationOffset: 0,
  hasMoreItems: true,
  hasAccess: false,
  isLoaded: false,
  hasReviews: true,
  isNewList: false,
  filterParams: {},
  reviews: [],
  review: null,
  total: 0
};

class KnowledgeBankProvider extends React.Component {
  constructor(props) {
    super(props);

    this.reviewAPI = ReviewService;

    this.state = {
      ...initialState,
      handleFetchList: this.getAllReviewForKnowledgeBank,
      handleFetchDetail: this.getReviewDetail,
      selectDetail: this.selectDetail,
      setHasAccess: this.setHasAccess,
      setInitialValues: this.setInitialValues
    };
  }

  setHasAccess = hasAccess => {
    this.setState({ hasAccess });
  };

  getAllReviewForKnowledgeBank = async (filterParams, initial = false, authorized) => {
    try {
      const isNewList = !!filterParams && _.isEmpty(filterParams);
      const state = !!filterParams ? initialState : this.state;
      const filter = !filterParams ? state.filterParams : filterParams;

      if (initial) {
        this.setState({
          isLoaded: false
        });
      }

      const { paginationLimit, paginationOffset } = state;
      const params = {
        limit: paginationLimit,
        offset: paginationOffset,
        ...filter
      };
      const reviewRequest = authorized ? 'getAllReviewForKnowledgeBank' : 'getAllReviewForKnowledgeBankUnAuth'
      const result = await this.reviewAPI[reviewRequest](params);
      const newReviews = [...state.reviews, ...result.data];
      const hasMoreItems = newReviews.length < result.totalCount;
      const hasReviews = !!newReviews.length;

      this.setState(state => ({
        reviews: newReviews,
        hasReviews: hasReviews,
        paginationOffset: paginationOffset + initialState.paginationLimit,
        hasMoreItems: hasMoreItems,
        hasAccess: true,
        isLoaded: true,
        filterParams: filter,
        isNewList: isNewList,
        total: result.totalCount || state.total
      }));
    } catch (e) {
      e.message === ACCESS_DENIED
        ? this.setState({
            hasAccess: false,
            isLoaded: true
          })
        : this.props.addError(e);
    } finally {
      this.setState({ ...this.state, isNewList: false });
    }
  };

  setInitialValues = () => {
    this.setState({
      ...initialState
    });
  };

  getReviewDetail = async (authorized, specialId, userId) => {
    try {
      if (authorized) {
        const result = await this.reviewAPI.getReviewForKnowledgeBank(specialId);
        const statusBuy = await this.reviewAPI.getBuyStatus({userId, reviewId: result.id})
        result.statusBuy = statusBuy
        this.setState(() => ({
          review: result,
          hasAccess: true,
          isLoaded: true
        }));
        return result;
      }
    } catch (e) {
      e.message === ACCESS_DENIED
        ? this.setState({
            hasAccess: false,
            isLoaded: true
          })
        : this.props.addError(e);
    } finally {
      this.setState({ ...this.state, isNewList: false });
    }
  };

  selectDetail = reviewId => {
    return _.find(this.state.reviews, { id: reviewId });
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

function withKnowledgeBank(Component) {
  return function ConnectedComponent(props) {
    return (
      <KnowledgeBankContext.Consumer>
        {value => <Component {...props} providerStore={value} />}
      </KnowledgeBankContext.Consumer>
    );
  };
}

const mapStateToProps = state => state;
const connectedKnowledgeBankProvider = connect(mapStateToProps, { addError })(
    KnowledgeBankProvider
);

export {
  connectedKnowledgeBankProvider as KnowledgeBankProvider,
  Consumer as KnowledgeBankConsumer,
  withKnowledgeBank
};
export default KnowledgeBankContext;
