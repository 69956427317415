import React, { useContext, useEffect, useState } from 'react';

import { Link, useRouteMatch } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
import classnames from 'classnames';
import styles from './style.module.scss';
import logo from '../../../assets/new-logo.png';

import UserContext from '../../contexts/UserContext';
import ConversationContext from '../../contexts/ConversationContext';

import Button from '../../../shared/components/Button';

const getLink = (label, to) => (
  <li>
    <Link to={to} className={styles.link}>
      {label}
    </Link>
  </li>
);

const getExternalLink = (label, link) => (
  <li>
    <a className={styles.link} href={link} target="_blank">
      {label}
    </a>
  </li>
);

const GetLinks = ({ type = 'desktop' }) => {
  const { isLoggedIn, user: { isAdmin, groups } } = useContext(UserContext);
  const { activeConversation } = useContext(ConversationContext);
  const isMobile = type === 'mobile';
  const className = isMobile ? styles.mobileLinks : styles.desktopLinks;

  const isGroupOwner = () => {
    return groups && !!groups.length;
  };

  return (
    <ul className={className}>
      {/*{isLoggedIn() &&*/}
      {/*getLink(*/}
      {/*'Messages',*/}
      {/*activeConversation ? `/messages/${activeConversation}` : '/messages',*/}
      {/*)}*/}
      {isLoggedIn() && isAdmin && type === 'mobile' && getLink('Admin Panel', '/admin-panel')}
      {isLoggedIn() && (isGroupOwner() || isAdmin) && type === 'mobile' && getLink('Group Panel', '/admin-group')}
      {isLoggedIn() && type === 'mobile' && getLink('Community', '/community')}
      {isLoggedIn() && type === 'mobile' && getLink('Knowledge Bank', '/knowledge-bank')}
      {isLoggedIn() && type === 'mobile' && getLink('Rewards', '/rewards')}
      {isLoggedIn() && type === 'mobile' && getLink('Dashboard', '/dashboard')}
      {isLoggedIn() && type === 'mobile' && getLink('Profile', '/settings')}
      {isLoggedIn() && type === 'mobile' && getExternalLink('How To Use KIP101', 'https://www.kip101.com/how-to-use-kip101')}
      {/* {isLoggedIn() && type === 'mobile' && getLink('Instruction', '/instruction')} */}
      {isLoggedIn() && type === 'mobile' && getLink('Logout', '/logout')}

      {/*{isLoggedIn() && type === 'mobile' && getLink('Community', '/#')}*/}
      {/*{isLoggedIn() && type === 'mobile' && getLink('Messages', '/#')}*/}
      {/*{isLoggedIn() && type === 'mobile' && getLink('Dashboard', '/#')}*/}
      {/*{isLoggedIn() && type === 'mobile' && getLink('Social media', '/#')}*/}

      {/* {!isLoggedIn() && getLink('Mission', '/mission')} */}
      {!isLoggedIn() && getLink('Sign In', '/login')}
    </ul>
  );
};

const getHamburger = onClickHamburger => (
  <div className={styles.hamburger}>
    <Button
      type='button'
      icon={<Icon icon='menu' iconSize={32} />}
      onClick={onClickHamburger}
      minimal
    />
  </div>
);

const getFilterButton = () => (
  <div id='filter-button' className={styles['filter-button']}></div>
);

const getLogo = () => (
  <Link to='/' className={styles.logoIcon}>
    <div className={styles.logoIcon}>
      <img src={logo} alt='KIP101' />
    </div>
  </Link>
);

const Header = props => {
  const { location } = props;
  const { key } = location;
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
  const [searchMenuOpened, setSearchMenuOpened] = useState(false);
  const { isLoggedIn } = useContext(UserContext);
  const match = useRouteMatch('/profile/:referralLink/:name');

  useEffect(() => setMobileMenuOpened(false), [key]);

  const mobileRowClassName = mobileMenuOpened
    ? styles.mobileRowOpen
    : styles.mobileRow;
  const headerClassName = isLoggedIn() ? styles.header : location.pathname === '/knowledge-bank' ? styles.headerNotLogin : match && match.path === '/profile/:referralLink/:name' ? styles.headerNotLogin : styles.header;
  const style = {
    visibility: isLoggedIn() || location.pathname === '/knowledge-bank' || match && match.path === '/profile/:referralLink/:name' ? 'visible' : 'hidden'
  };  

  const onClickHamburger = event => {
    event.preventDefault();
    setMobileMenuOpened(!mobileMenuOpened);
  };

  return (
    <header className={headerClassName} style={style}>
      <div className={styles.headerContainer}>
        <div className={styles.headerWrapper}>
          <div className='row'>
            <div className='col-xs-12'>
              {getHamburger(onClickHamburger)}
              {getLogo()}
              {getFilterButton()}
              {}
            </div>
          </div>
          <div className={classnames('row', mobileRowClassName)}>
            <div className='col-xs-12'>
              <GetLinks type='mobile' />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
