import MobileDetect from 'mobile-detect';

// Site configuration
window.siteConfig = {
  ...process.env,
};

// Mobile Detect
window.md = new MobileDetect(window.navigator.userAgent);
window.isDev = process.env.NODE_ENV === 'development';
