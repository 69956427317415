import React from 'react';
import Helmet from 'react-helmet';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import * as Sentry from "@sentry/react";

// Contexts
import UserContext, { UserProvider } from './contexts/UserContext';
import { TagsProvider } from './contexts/TagsContext';
import { ProviderProvider } from './contexts/ProviderContext';
import { ConversationProvider } from './contexts/ConversationContext';
import { CreditProvider } from "./contexts/CreditContext";
import { InviteCodeProvider } from "./contexts/InviteCodeContext";
import { ConnectionProvider } from "./contexts/ConnectionContext";
import { KnowledgeBankProvider } from "./contexts/KnowledgeBankContext";
import { RewardsModuleProvider } from "./contexts/RewardsModuleContext";

// Components (Not Lazy Loaded)
import ScrollTop from '../shared/components/ScrollTop';
import Header from './components/Header';
// import Footer from './components/Footer';

// Components (Lazy Loaded)
import Routes from './Routes';

import styles from './App.module.scss';
import { connect } from 'react-redux';
import { showErrorToast } from './Notifier';
import { cleanErrorsCache } from './redux/actions';
import { ReviewProvider } from './contexts/ReviewContext';
import { FavoriteProvider } from './contexts/FavoriteContext';
import { RewardProvider } from './contexts/RewardContext';

const siteConfig = window.siteConfig || {};
const deployEnv = siteConfig.REACT_APP_DEPLOY_ENV;

class Component extends React.PureComponent {
  static contextType = UserContext;
  state = {
    errors: []
  }

  componentDidMount() {
    const { onBootstrap } = this.context;
    onBootstrap();
  }

  componentWillReceiveProps = newProps => {
    const { errors, cleanErrorsCache } = newProps;
    if (errors.length && errors[0].message === "Provided referral or group code is invalid") {
      cleanErrorsCache();
      return;
    }

    if (errors.length) {
      errors.forEach(showErrorToast);
      cleanErrorsCache();
    }
  };

  render() {
    const { isBootstrapped } = this.context;

    if (!isBootstrapped()) {
      // Don't render ANYTHING until bootstrap API request is made and localization is loaded
      return null;
    }

    // Site wide meta tags
    const defaultTitle = 'KIP101';
    const description = 'KIP101s mission is to help people get the information they need to succeed professionally. Our free app connects people for 1-on-1 conversations and knowledge-sharing that drive professional development & advancement.';
    const keywords = 'informational interview, professional networking, professional development, career advice, career coaching, career advancement, networking platform, career opportunities, career exploration, career tracks, career paths, career insights on demand, knowledge sharing, 1-on-1 career chats';
    const meta = [
      { name: 'keywords', content: keywords },
      { name: 'description', content: description },
    ];

    // https://developers.google.com/search/docs/advanced/crawling/block-indexing
    if (deployEnv !== 'production') {
      meta.push({
        name: 'robots',
        content: 'noindex',
      })
    }

    return (
      <div className={styles.app}>
        <Helmet
          defaultTitle={defaultTitle}
          titleTemplate={`%s | ${defaultTitle}`}
          meta={meta}
        />

        <Route component={Header} />
        <div id='main' role='main' className={styles.main}>
          <div>
            <Routes errors={this.state.errors} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errors: state.xhr.errors
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  { cleanErrorsCache }
)(Component);

const App = () => (
  <UserProvider>
    <ProviderProvider>
      <RewardsModuleProvider>
        <KnowledgeBankProvider>
          <ConversationProvider>
            <ConnectionProvider>
              <CreditProvider>
                <InviteCodeProvider>
                  <TagsProvider>
                    <ReviewProvider>
                      <RewardProvider>
                        <FavoriteProvider>
                          <Router>
                            <ScrollTop>
                              <Route component={ConnectedComponent} />
                            </ScrollTop>
                          </Router>
                        </FavoriteProvider>
                      </RewardProvider>
                    </ReviewProvider>
                  </TagsProvider>
                </InviteCodeProvider>
              </CreditProvider>
            </ConnectionProvider>
          </ConversationProvider>
        </KnowledgeBankProvider>
      </RewardsModuleProvider>
    </ProviderProvider>
  </UserProvider>
);

export default Sentry.withProfiler(App);
