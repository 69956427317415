import _ from 'lodash';
import React from 'react';

import RewardService from '../services/RewardService';
import { connect } from 'react-redux';
import { addError } from '../redux/actions/request';

const ACCESS_DENIED = 'ACCESS_DENIED';

const RewardsModuleContext = React.createContext();
const { Provider, Consumer } = RewardsModuleContext;

const initialState = {
  paginationLimit: 8,
  paginationOffset: 0,
  hasMoreItems: true,
  hasAccess: false,
  isLoaded: false,
  hasRewards: true,
  isNewList: false,
  filterParams: {},
  rewards: [],
  highPrice: 0,
  reward: null,
  total: 0,
  newGrouping: {},
  newGroupingFlag: true,
};

class RewardsModuleProvider extends React.Component {
  constructor(props) {
    super(props);

    this.rewardAPI = RewardService;

    this.state = {
      ...initialState,
      handleFetchList: this.getAllRewardsForRewardsModule,
      handleFetchDetail: this.getRewardDetail,
      selectDetail: this.selectDetail,
      setHasAccess: this.setHasAccess,
      setInitialValues: this.setInitialValues,
      clearNewGrouping: this.clearNewGrouping,
      getMaxPrice: this.getMaxPrice,
      decreaseRewardsCount: this.decreaseRewardsCount,
      getFilteredData: this.getFilteredData,
    };
  }

  setHasAccess = (hasAccess) => {
    this.setState({ hasAccess });
  };

  getMaxPrice = async (userId) => {
    const result = await this.rewardAPI.getMaxPrice(userId);
    this.setState({
      highPrice: result.maxPrice,
    });
  };

  clearNewGrouping = () => {
    this.setState({
      newGrouping: {},
    });
  };

  getFilteredData = (filterParams, initial = false, authorized) => {
    this.setState({
      newGrouping: {},
    });
    this.getAllRewardsForRewardsModule(filterParams, initial, authorized);
  };

  getAllRewardsForRewardsModule = async (
    filterParams,
    initial = false,
    authorized
  ) => {
    try {
      this.setState({
        newGroupingFlag: true
      })
      const isNewList = !!filterParams && _.isEmpty(filterParams);
      const state = !!filterParams ? initialState : this.state;
      const filter = !filterParams ? state.filterParams : filterParams;

      if (initial) {
        this.setState({
          isLoaded: false,
        });
      }

      const { paginationLimit, paginationOffset } = state;
      const params = {
        limit: paginationLimit,
        offset: paginationOffset,
        ...filter,
      };
      const result = await this.rewardAPI.getAllRewardForRewardsModule(params);

      result.data.map(item => {
        if(item.groupName === ""){
          item.groupName = 'KIP101'
        }
        return item;
      })

      const newGrouping = result.data.reduce((grp, item) => {
        if (grp[item.groupName]) {
          grp[item.groupName].rewards = [...grp[item.groupName].rewards, item];
        } else {
          grp[item.groupName] = {
            groupName: item.groupName,
            rewards: [item],
          };
        }
        return grp;
      }, this.state.newGrouping);

      this.setState({
        newGrouping,
      });

      const newRewards = [...state.rewards, ...result.data];
      const hasMoreItems = newRewards.length < result.totalCount;
      const hasRewards = !!newRewards.length;

      this.setState((state) => ({
        rewards: newRewards,
        hasRewards: hasRewards,
        paginationOffset: paginationOffset + initialState.paginationLimit,
        hasMoreItems: hasMoreItems,
        hasAccess: true,
        isLoaded: true,
        filterParams: filter,
        isNewList: isNewList,
        total: result.totalCount || state.total,
      }));
      this.setState({
        newGroupingFlag: false
      })
    } catch (e) {
      e.message === ACCESS_DENIED
        ? this.setState({
            hasAccess: false,
            isLoaded: true,
          })
        : this.props.addError(e);
    } finally {
      this.setState({ ...this.state, isNewList: false });
    }
  };

  decreaseRewardsCount = (groupName, rewardId, count) => {
    const rewards = this.state.newGrouping[groupName].rewards.reduce(
      (rew, reward) => {
        let quantityRedeemed = reward.quantityRedeemed;
        let newReward = reward;
        if (reward.id === rewardId) {
          quantityRedeemed = reward.quantityRedeemed + count;
          newReward = {
            ...reward,
            quantityRedeemed,
          };
        }
        return reward.quantityOffered === quantityRedeemed
          ? rew
          : [...rew, newReward];
      },
      []
    );

    let newGrouping;
    if (rewards.length) {
      newGrouping = {
        ...this.state.newGrouping,
        [groupName]: {
          ...this.state.newGrouping[groupName],
          rewards,
        },
      };
    } else {
      newGrouping = {
        ...this.state.newGrouping,
      };
      delete newGrouping[groupName];
    }
    this.setState({
      newGrouping,
    });
  };

  setInitialValues = () => {
    this.setState({
      ...initialState,
    });
  };

  selectDetail = (rewardId) => {
    return _.find(this.state.rewards, { id: rewardId });
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

function withRewardsModule(Component) {
  return function ConnectedComponent(props) {
    return (
      <RewardsModuleContext.Consumer>
        {(value) => <Component {...props} providerStore={value} />}
      </RewardsModuleContext.Consumer>
    );
  };
}

const mapStateToProps = (state) => state;
const connectedRewardsModuleProvider = connect(mapStateToProps, { addError })(
  RewardsModuleProvider
);

export {
  connectedRewardsModuleProvider as RewardsModuleProvider,
  Consumer as RewardsModuleConsumer,
  withRewardsModule,
};
export default RewardsModuleContext;
