import _ from 'lodash';
import uuid from 'uuid';
import { combineReducers } from 'redux';
import { createActions, handleActions, combineActions } from 'redux-actions';

import cookie from '../../utils/cookie';

/* Actions */

export const actions = createActions(
  {},
  'ROOT_ERROR',
  'CONFIG_RESTORE_PID',
  'CONFIG_SET_DARK',
  'CONFIG_RESTORE_DARK',
  'CONFIG_SET_LANGUAGE',
  'CONFIG_RESTORE_LANGUAGE',
  'CONFIG_SET_AUTOPLAY',
  'CONFIG_RESTORE_AUTOPLAY',
  'CONFIG_SET_TIMEZONE',
  'CONFIG_LOCALIZATION_REQUEST',
  'CONFIG_LOCALIZATION_RESTORE',
  'CONFIG_LOCALIZATION_SUCCESS',
  'CONFIG_LOCALIZATION_ERROR',
  'CONFIG_SET_FULLSCREEN',
  'CONFIG_SET_SEARCH',
);

/* Reducers */

export default combineReducers({
  // This `isBootstrapped` needs to be here (and not in `account` reducer)
  // because in the`root` reducer, we wipe the `account` reducer on certain actions
  isBootstrapped: handleActions(
    {
      [combineActions('ACCOUNT_BOOTSTRAP_DONE')]: (state, action) => {
        if (action.type === 'ACCOUNT_BOOTSTRAP_DONE') {
          return true;
        }

        return state;
      },
    },
    false,
  ),
  pid: handleActions(
    {
      CONFIG_RESTORE_PID() {
        let pid = cookie.getPid();
        if (_.isUndefined(pid)) {
          pid = uuid.v4();
          console.debug('Setting pid', pid);
          cookie.setPid(pid);
        }
        return pid;
      },
    },
    '',
  ),
  timezoneOffset: handleActions(
    {
      CONFIG_SET_TIMEZONE() {
        const now = new Date();
        return now.getTimezoneOffset ? now.getTimezoneOffset() : 0;
      },
    },
    0,
  ),
  xhrError: handleActions({
    XHR_ERROR: (state, action) => {
      return {
        ...state,
        errors: [
          ...state.errors,
          action.error,
        ]
      };
    },
  }, {
    errors: [],
  })
});
