// https://reactjs.org/docs/code-splitting.html

import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import UserContext from './contexts/UserContext';

import ErrorBoundary from '../shared/components/ErrorBoundary';

// Public
const Home = lazy(() => import('./components/Home'));
const KnowledgeProvider = lazy(() => import('./components/Info/Provider'));
const KnowledgeSeeker = lazy(() => import('./components/Info/Seeker'));
const Mission = lazy(() => import('./components/Mission'));
const Instruction = lazy(() => import('./components/Instruction'));
const NotFound = lazy(() => import('./components/NotFound'));
const Login = lazy(() => import('./components/Login'));
const LinkedinCallback = lazy(() => import('./components/Login/LinkedinCallback'));
const Register = lazy(() => import('./components/Register'));
const ForgotPassword = lazy(() => import('./components/ForgotPassword'));
const ResetPassword = lazy(() => import('./components/ResetPassword'));
const Logout = lazy(() => import('./components/Logout'));
const Verification = lazy(() => import('./components/Verification'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const Terms = lazy(() => import('./components/Terms'));
const NoProfileAccess = lazy(() => import('./components/Community/NoProfileAccess'))
const KnowledgeBank = lazy(() => import('./components/KnowledgeBank'));

// Private
const Community = lazy(() => import('./components/Community'));
const Messages = lazy(() => import('./components/Messages'));
const Settings = lazy(() => import('./components/Settings'));
const Dashboard = lazy(() => import('./components/Dashboard'));
const UserProfile = lazy(() => import('./components/UserProfile'));
const Rewards = lazy(() => import('./components/RewardsModule'));

// Admin
const AdminPanel = lazy(() => import('./components/AdminPanel'));
const Groups = lazy(() => import('./components/GroupOwnerModule'));

const ProfileDetail = lazy(() =>
  import('./components/Community/ProfileDetail'),
);

const ReviewDetail = lazy(() =>
  import('./components/KnowledgeBank/ReviewDetail'),
);

const PrivateRoute = ({ component: Component, isLoggedIn, errors, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        !isLoggedIn ? (
          <Redirect
            to={{
              pathname: '/login',
              state: { fromPathname: props.location.pathname }, // eslint-disable-line
            }}
          />
        ) : (
          <Component {...props} appErrors={errors} />
        )
      }
    />
  );
};

const AdminRoute = ({ component: Component, isLoggedIn, isAdmin, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        !isLoggedIn ? (
          <Redirect
            to={{
              pathname: '/login',
              state: { fromPathname: props.location.pathname }, // eslint-disable-line
            }}
          />
        ) : isAdmin ? (
          <Component {...props} />
        ) : (
          <NotFound />
        )
      }
    />
  );
};

const GroupRoute = ({ component: Component, isLoggedIn, isGroupOwner, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        !isLoggedIn ? (
          <Redirect
            to={{
              pathname: '/login',
              state: { fromPathname: props.location.pathname }, // eslint-disable-line
            }}
          />
        ) : isGroupOwner ? (
          <Component {...props} />
        ) : (
          <NotFound />
        )
      }
    />
  );
};

const CommunityProfileRoute = ({ component: Component, isLoggedIn, errors, ...rest }) => {

  return (
    <Route
    {...rest}
    render={props =>
      !isLoggedIn ? (
        <NoProfileAccess />
      ) : (
        <Component {...props} appErrors={errors} />
      )
    }
  />
  )
}

class Routes extends React.Component {
  static contextType = UserContext;

  render() {
    const { isLoggedIn, user: { isAdmin }, isGroupOwner, owner } = this.context;

    return (
      <ErrorBoundary>
        <Suspense fallback={<div />}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/mission" component={Mission} />
            <Route exact path="/instruction" component={Instruction} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/login/linkedin/callback" component={LinkedinCallback} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/forgot_password" component={ForgotPassword} />
            <Route exact path="/verifications/:token" component={Verification} />
            <Route
              exact
              path="/reset_password/:token"
              component={ResetPassword}
            />
            <Route exact path="/logout" component={Logout} />

            <Redirect from="/info" to="/info/seeker" exact />
            <Route path="/info/seeker" component={KnowledgeSeeker} />
            <Route path="/info/provider" component={KnowledgeProvider} />

            <PrivateRoute
              exact
              path="/community"
              component={Community}
              isLoggedIn={isLoggedIn()}
            />
            <Route
              path="/profile/:referralLink/:name"
              component={ProfileDetail}
            />
            <Route
              path="/knowledge-bank"
              component={KnowledgeBank}
            />
            <PrivateRoute
              path="/rewards"
              component={Rewards}
              isLoggedIn={isLoggedIn()}
            />
            <PrivateRoute
              path="/career-path-review/:specialId/:name"
              component={ReviewDetail}
              isLoggedIn={isLoggedIn()}
            />
            <PrivateRoute
              path="/messages"
              component={Messages}
              isLoggedIn={isLoggedIn()}
            />
            <Redirect from="/settings" to="/settings/profile" exact />
            <PrivateRoute
              path="/settings"
              component={UserProfile}
              isLoggedIn={isLoggedIn()}
              errors={this.props.errors}
            />
            <PrivateRoute
              path="/dashboard"
              component={Dashboard}
              isLoggedIn={isLoggedIn()}
            />

            <AdminRoute
              path="/admin-panel"
              component={AdminPanel}
              isLoggedIn={isLoggedIn()}
              isAdmin={isAdmin}
            />

            <GroupRoute
              path="/admin-group"
              component={Groups}
              isLoggedIn={isLoggedIn()}
              isGroupOwner={isGroupOwner() || isAdmin || owner}
            />

            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/terms-and-conditions" component={Terms} />

            <Route component={NotFound} />
            <Route component={NoProfileAccess} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    );
  }
}

export default Routes;
